<template>
  <div>
    <general-table
      ref="campaignsTable"
      :api-url="apiUrl"
      :add-type="addType"
      :columns="columns"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :viw-component="viwComponent"
      :view-content="true"
      :search-box="false"
      :type="type"
      :selectable="false"
    >
      <template #filter>
        <b-col
          cols="12"
          md="3"
        >
          <b-input-group>
            <b-form-select
              v-model="filter.is_active"
              value-field="value"
              text-field="text"
              :options="status"
            >
              <template #first>
                <b-form-select-option
                  disabled
                >
                  Status
                </b-form-select-option>
              </template>
            </b-form-select>

            <b-input-group-append v-if="filter.is_active">
              <b-button
                size="sm"
                @click="filter.is_active = ''"
              >
                <span class="lead">
                  &times;
                </span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-button
          variant="primary"
          :disabled="Object.values(filter).length < 1"
          @click="$refs.campaignsTable.getAllData()"
        >
          Filter
        </b-button>

      </template>
      <template #cell(name_en)="data">
        <router-link :to="{ name: 'show-campaign', params: { id: data.item.id } }">
          {{ data.item.name }}
        </router-link>

      </template>
      <template #cell(products)="data">
        <span>
          {{ data.item.products.length }}</span>
      </template>
      <template #cell(country_name)="data">
        <span>
          <img
            v-img
            width="30"
            :src="data.item.flag_image"
          > {{ data.item.country_name }}</span>
      </template>
      <template #cell(images)="data">

        <img
          v-if="data.item.images.length>0"
          v-img
          width="70"
          :src="data.item.images[0].url"
        >
      </template>
      <template #cell(quantity_limit)="data">
        <span v-if="data.item.quantity_limit!==null">{{ data.item.quantity_limit }}</span>
        <span v-else>Unlimted</span>
      </template>
      <template #cell(sections)="data">
        <router-link :to="{ name: 'campaign-sections', params: { id: data.item.id } }">
          <span
            title="Add sections"
          >
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
            />
          </span>
        </router-link>

      </template>
      <template
        #cell(is_active)="data"
      >
        <b-badge
          class="change-status"
          :variant="changeVaraint(data.item.is_active)"
        >
          {{ getTypes(data.item.is_active) }}
        </b-badge>
      </template>
    </general-table>

  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      addType: 'Add New Campaign',
      type: 'page',
      urlType: 'campaigns',
      addComponentName: 'add-campaign',
      editComponent: 'edit-campaign',
      viwComponent: 'show-campaign',
      filter: {},
      status: [
        { text: 'Active', value: '1' },
        // { text: 'Not Active', value: '0' },
      ],
      types: '',
      columns: [
        { key: 'id' },
        { key: 'images', label: 'Image' },
        { key: 'name_en', label: 'Campaign name' },
        { key: 'products', label: 'Products' },
        { key: 'gift_name', label: 'Gift name' },
        { key: 'is_active', label: 'Display' },
        { key: 'country_name', label: 'Country' },
        { key: 'number_for_free_tickets', label: 'No. Of Free Tickets' },
        { key: 'ticket_count', label: 'Tickets Count' },
        { key: 'quantity_limit', label: 'Quantity limit' },
        { key: 'sections', label: 'Sections' },
        { key: 'actions' },
      ],
    }
  },
  computed: {

    apiUrl() {
      let base = 'admin/product_gifts?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
  },
  methods: {
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type === 1) return 'success'

      return 'danger'
    },

    getTypes(type) {
      if (type === 1) {
        return 'Displayed'
      } if (type === 0) {
        return 'Hidden'
      }
      return 'Displayed'
    },

  },
}
</script>

<style>

</style>
